import { _ } from "core-js";

export default {
    data() {
        return {
            pagination: {
                componentPath: this.$route.path,
                listUrl: "",
                vuex: "",
                page: 1,
                limit: 10,
                count: 0,
                aditionalQuery:''
            },
            apiAddress: 'http_get_sender'
        }
    },
    watch: {
        async $route(to, from) {
            if (to.path == this.pagination.componentPath) {
                if (to.query.page && to.query.limit) {
                    console.log('watched if', to)
                    this.pagination.page = to.query.page;
                    this.pagination.limit = to.query.limit;
                    this.get_list();
                } else {

                    this.$router.replace({
                        query: { ...this.aditionalQuery, page: this.pagination.page, limit: this.pagination.limit },
                    }).catch(e => { })
                }
            }
        },
    },
    methods: {
        async get_list() {
            this.ready = false;
            await this.get_query(this.pagination.listUrl, this.pagination.vuex);
            console.log(this.ready)
            this.ready = true;
        },
        generate_query_string(queryObject) {
            let queryString = ''
            const queries = Object.keys(queryObject);
            _.forEach(queries, (query, index) => {
                queryString = queryString + `${query}=${queryObject[query]}`;
                if (index + 1 !== queries.length) {
                    queryString = queryString + "&";
                }
            });
            return queryString
        },
        set_initial_query() {
            let { page, limit } = this.$route.query;
            if (!page || !limit) {
                page = this.pagination.page
                limit = this.pagination.limit
            } else {
                this.pagination.page = page;
                this.pagination.limit = limit;
            }

            let query = { page: this.pagination.page, limit: this.pagination.limit }

            if (!_.isEmpty(this.pagination.aditionalQuery)) {
                query = { ...query, ...this.pagination.aditionalQuery }
            }

            return query
        },
        async get_query(url, vuex) {
            let queryString = await this.generate_query_string(this.$route.query);

            const response = await this.$store.dispatch(this.apiAddress, {
                url: `${url}?${queryString}`,
                vuex,
            });

            console.log(response)

            this.pagination.count = response.data.payload.count;

            if (response.data.page > response.data.last_page) {
                this.$router.push({
                    query: { ...this.$route.query, page: 1 },
                }).catch(e => { })
            }
        },
        async init_pagination({ url, vuex, apiAddress, aditionalQuery }) {
            if (apiAddress) {

                this.apiAddress = apiAddress
            }
            if (aditionalQuery) {

                this.pagination.aditionalQuery = aditionalQuery
            }
            this.pagination.listUrl = url
            this.pagination.vuex = vuex
            const query = this.set_initial_query()
            console.log('mounted', query)
            this.$router.push({ query }).catch(async (e) => {
                if (e.name == "NavigationDuplicated") {
                    await this.get_list();
                }
            });

        }
    }
}