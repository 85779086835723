<template>
  <div class="mt-3">
    <strong>ENVIADOS</strong>
    <TableList
      label="ENVIADOS"
      :fields="fields.file"
      :items="list"
      :ready="ready"
    />
    <Pagination v-if="showPagination" v-model="pagination" :ready="ready" />
  </div>
</template>

<script>
import paginationMixins from "@/mixin/paginationMixin";
import fields from "../fields";
export default {
  props: ["lote"],
  data() {
    return {
      showPagination: true,
      ready: true,
      fields,
    };
  },
  computed: {
    list() {
      return this.$store.state.sender.sentList.rows;
    },
  },
  methods: {
    get_sent_list(value) {
      let aditionalQuery = {};
      if (value) {
        aditionalQuery = { lote: this.lote, search: value };
        this.showPagination = false;
      } else {
        aditionalQuery = { lote: this.lote, page: 1 };
        this.showPagination = true;
      }
      //   setTimeout(() => {
      this.init_pagination({
        url: `/boletos/get_file`,
        vuex: "sender/sentList",
        aditionalQuery,
      });
      //   }, 5000);
    },
  },
  mounted() {
    this.init_pagination({
      url: `/boletos/get_file`,
      vuex: "sender/sentList",
      aditionalQuery: { lote: this.lote, page: 1 },
    });
  },
  mixins: [paginationMixins],
};
</script>

<style></style>
